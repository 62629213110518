.root {
    @apply flex items-center justify-center break-words overflow-auto;
}

.root table {
    @apply border-collapse;
}

.root table th, .root table td {
    @apply table-cell p-2 border border-solid border-black
}

.copy {
    @apply opacity-0 transition-all duration-150 text-[0px] cursor-pointer;
}

.inner {
    @apply max-w-[90%] flex relative rounded-xl p-4 pr-[70px] bg-primary-bg-hover
}

.inner:hover .copy {
    @apply opacity-100 text-xl
}

.root code, .root pre {
    @apply whitespace-pre-wrap break-words;
}

.root pre code {
    @apply w-full block bg-gray-border p-2.5 my-2.5 rounded-lg;
}

.root img {
    @apply w-full max-w-full h-auto cursor-pointer;
}
