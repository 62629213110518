.card {
    @apply break-words overflow-auto;
}

.card table {
    @apply border-collapse;
}

.card table th, .card table td {
    @apply table-cell p-2 border border-solid border-black
}

.card code, .card pre {
    @apply whitespace-pre-wrap break-words;
}

.card pre code {
    @apply w-full block bg-gray-border p-4 my-2.5 rounded-lg;
}



.card img {
    @apply w-full max-w-full h-auto cursor-pointer;
}
