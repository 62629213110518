.root {
    @apply min-w-[900px]
}

.root [class*='modal-content'] {
    @apply p-0
}

.content {
    @apply p-5 pr-[220px] bg-no-repeat bg-[length:200px_200px] bg-[right_bottom_-50px] rounded-lg;
}
