.root {
    @apply flex flex-col bg-gray-bg hover:bg-gray-bg-hover rounded-2xl p-4 w-[356px] min-w-[356px] min-h-[250px] max-h-[250px]
}

.favourite {
    @apply opacity-0 text-[0px] transition-all duration-150
}

.private {
    @apply ml-1.5 opacity-0 transition-all duration-150 text-black text-sm;
}

.root:hover .private {
    @apply opacity-[0.40]
}

.root:hover .favourite {
    @apply opacity-100 text-xl
}
