.root label {
    @apply !text-black font-medium
}

.text {
    @apply relative;
}

.text [class*='ant-input-data-count']{
    @apply absolute right-0 bottom-[100%]
}

.text label {
    @apply !h-auto;
}
