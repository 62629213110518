.groupItem {
    @apply w-[408px] max-w-[400px] h-[112px] p-5 rounded-xl border border-solid border-gray-border-dark hover:border-2 border-primary-active hover:!bg-white cursor-pointer flex w-full items-center gap-x-3;
}

.menuAction {
    @apply w-[408px] max-w-[400px] h-[112px] p-5 rounded-xl bg-gray-bg hover:!bg-gray-bg-hover cursor-pointer flex w-full items-center gap-x-3;
}

.groupItem:hover .arrow {
    @apply text-primary-active;
}

.arrow {
    @apply ml-auto;
}
